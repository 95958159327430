import 'iframe-resizer/js/iframeResizer.contentWindow';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import './index.css';
import storage from './storage';
import { isJSON } from './utils/isJson';

window.addEventListener('message', (e: MessageEvent) => {
  const data = typeof e.data === 'string' && isJSON(e.data) ? JSON.parse(e.data) : null;

  if (data && data.type === 'module-init') {
    try {
      window.sessionStorage.setItem('parentHref', data.values.parentHref);
    } catch (e) {
      storage({
        parentHref: data.values.parentHref,
      });
    }
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
