export const genericStorefrontUrl = import.meta.env.VITE_STOREFRONT_API_URL;

const urlOrFallback = (envUrl?: string | null) => {
  return envUrl || genericStorefrontUrl;
};

export const storefrontUrlForEngager = urlOrFallback(
  import.meta.env.VITE_STOREFRONT_API_URL_FOR_ENGAGER,
);
export const storefrontUrlForPropertyPicker = urlOrFallback(
  import.meta.env.VITE_STOREFRONT_API_URL_FOR_PROPERTY_PICKER,
);
export const storefrontUrlForCheckout = urlOrFallback(
  import.meta.env.VITE_STOREFRONT_API_URL_FOR_CHECKOUT,
);

export const storefrontUrlForSubdomainModule = urlOrFallback(
  import.meta.env.VITE_STOREFRONT_API_URL_FOR_SUBDOMAIN_MODULE,
);

// We use different identifier strings in different contexts
// Thus the synonims

export type ModuleIdentifier =
  | 'project_news'
  | 'engager'
  | 'property_picker'
  | 'property_pickers'
  | 'property-explorer'
  | 'checkout';

export const getStorefrontUrlForModule = (module: ModuleIdentifier): string => {
  switch (module) {
    case 'project_news':
    case 'engager':
      return storefrontUrlForEngager;
    case 'property_pickers':
    case 'property_picker':
    case 'property-explorer':
      return storefrontUrlForPropertyPicker;
    case 'checkout':
      return storefrontUrlForCheckout;
    default:
      return genericStorefrontUrl;
  }
};
