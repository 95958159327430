import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { ModuleIdentifier } from '~/helpers/getStorefrontUrl';

import { useClients } from '../hooks/useClients';

const searchParams = new URLSearchParams(window.location.search);

export type ClientWrapperProps = {
  explicitCompanyUuid?: string | null;
  children: ReactNode;
  module: ModuleIdentifier;
};

export const ClientWrapper = ({ explicitCompanyUuid, children, module }: ClientWrapperProps) => {
  const { companyUuid } = useParams();
  const companyUuidFromParams = searchParams.get('company') || undefined;

  const { token } = useClients({
    companyUuid: explicitCompanyUuid || companyUuid || companyUuidFromParams,
    module,
  });

  if (!token) return null;

  return <>{children}</>;
};
